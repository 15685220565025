import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    InputAdornment,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    LinearProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import enGB from 'date-fns/locale/en-GB';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiPostFormdata, apiPostJson } from '../../../Utilities/API';
import { useSelector } from 'react-redux';

const OwnersOfferCreator = ({ storeList }) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const userData = useSelector(state=>state.user.userData)
    const offerId = searchParams.get('offerID'); // Get offer_id from URL
    
    // State variables for offer data
    const [selectedStore, setSelectedStore] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [discountCode, setDiscountCode] = useState('');
    const [minimumPurchase, setMinimumPurchase] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [discount, setDiscount] = useState('');
    const [discountType, setDiscountType] = useState('%');
    const [terms, setTerms] = useState([]);
    const [newTerm, setNewTerm] = useState('');
    const [bannerImage, setBannerImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    
    const fetchOfferList = (offer_id) => {
        setLoading(true);
        setTimeout(()=>{
            setLoading(false)
        },2000)
        apiPostJson(
            'ag-api/store-owner/',
            { identifier: 'get_offers_list', mobile: userData?.mobile, offer_id },
            (response) => {
                if (Array.isArray(response.offers_list) && response.offers_list.length > 0) {
                    let offer = response.offers_list[0]
                    setSelectedStore(offer.store_id);
                    setTitle(offer.offer_title);
                    setDescription(offer.offer_description);
                    setDiscountCode(offer.discount_code);
                    setMinimumPurchase(offer.min_purchase);
                    setStartDate(new Date(offer.start_date));
                    setExpiryDate(new Date(offer.expiry_date));
                    setDiscount(offer.discount);
                    setDiscountType(offer.discount_type);
                    setTerms(offer.terms ? JSON.parse(offer.terms) : []);
                    if (offer.banner_image) {
                        setImagePreview(window.getMediaImageURL(offer.banner_image));
                    }
                } else {
                }
                setLoading(false);
            },
            (response) => {
                setLoading(false);
            }
        );
    };

    const gotoStoreOwnerHome = ()=>{
        navigate('/store-owners-corner/')
    }

    useEffect(() => {
        if (offerId) {
            fetchOfferList(offerId)
        }
    }, [offerId]);

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        
        if (!title  || !selectedStore) {
            alert("Store and Title are required");
            return;
        }

        if (discountType === '%' && discount > 99) {
            alert("Discount percentage cannot exceed 99%");
            return;
        }

        const formData = new FormData();
        formData.append("store_id", selectedStore);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("discount_code", discountCode);
        formData.append("min_purchase", minimumPurchase);
        startDate && formData.append("start_date", startDate ? startDate.toISOString().split('T')[0] : null);
        expiryDate && formData.append("expiry_date", expiryDate ? expiryDate.toISOString().split('T')[0] : null);
        formData.append("discount", discount);
        formData.append("discount_type", discountType);
        formData.append("terms", JSON.stringify(terms));

        if (bannerImage) {
            formData.append("banner_image", bannerImage);
        }

        if(offerId){
            formData.append("offer_id", offerId);
        }

        apiPostFormdata(
            'ag-api/store-owner/create-offer/',
            formData,
            (response) => {
                alert('Offer succesfully saved')
                navigate('/store-owners-corner/')
                console.log("Success:", response)
                setLoading(false)
            },
            (error) => { alert('Unable to create offer'); setLoading(false); console.error("Error:", error)}
        );
    };

    const addTerm = () => {
        if (newTerm.trim()) {
            setTerms([...terms, newTerm.trim()]);
            setNewTerm('');
        }
    };

    const removeTerm = (index) => {
        setTerms(terms.filter((_, i) => i !== index));
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const isValid = await isValidImage(file);
            if (isValid) {
                setBannerImage(file);
                const reader = new FileReader();
                reader.onloadend = () => setImagePreview(reader.result);
                reader.readAsDataURL(file);
            } else {
                alert("Please upload a valid image file (PNG, JPG, GIF)");
                setBannerImage(null);
                setImagePreview(null);
            }
        }
    };

    const isValidImage = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const arr = new Uint8Array(reader.result).subarray(0, 4);
                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }
                switch (header) {
                    case "89504e47": // PNG
                    case "ffd8ffe0": // JPG (JPEG)
                    case "ffd8ffe1": // JPG (JPEG)
                    case "ffd8ffe2": // JPG (JPEG)
                    case "ffd8ffe3": // JPG (JPEG)
                    case "ffd8ffe8": // JPG (JPEG)
                    case "47494638": // GIF
                        resolve(true);
                        break;
                    default:
                        resolve(false);
                        break;
                }
            };
            reader.onerror = () => resolve(false);
            reader.readAsArrayBuffer(file.slice(0, 4));
        });
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
            {loading && <LinearProgress/> }
            <Paper elevation={3} style={{ padding: '2rem' }}>
                <Typography variant="h4" gutterBottom align="center">
                    {offerId ? "Edit Offer" : "Create New Offer"}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>Store</InputLabel>
                                <Select
                                    value={selectedStore}
                                    onChange={(e) => setSelectedStore(e.target.value)}
                                    label="Store"
                                >
                                    {storeList.map((store) => (
                                        <MenuItem key={store.store_id} value={store.store_id}>
                                            {store.store_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={enGB}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newDate) => setStartDate(newDate)}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth required />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={enGB}>
                                <DatePicker
                                    label="Expiry Date"
                                    value={expiryDate}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newDate) => setExpiryDate(newDate)}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth required />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Discount Type</InputLabel>
                                <Select
                                    value={discountType}
                                    onChange={(e) => setDiscountType(e.target.value)}
                                    label="Discount Type"
                                >
                                    <MenuItem value="%">%</MenuItem>
                                    <MenuItem value="₹">₹</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Discount"
                                variant="outlined"
                                type="number"
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Minimum Purchase (Rs)"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                }}
                                value={minimumPurchase}
                                onChange={(e) => setMinimumPurchase(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Discount Code"
                                variant="outlined"
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Terms & Conditions</Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        label="New Term"
                                        variant="outlined"
                                        value={newTerm}
                                        onChange={(e) => setNewTerm(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={addTerm}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                            <List>
                                {terms.map((term, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={term} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => removeTerm(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <label htmlFor="bannerImage" className="form-label">Upload Banner Image</label>
                            <input
                                type="file"
                                id="bannerImage"
                                className="form-control"
                                onChange={handleImageChange}
                                accept="image/*"
                            />
                            {imagePreview && (
                                <div className="mt-3">
                                    <img src={imagePreview} alt="Banner Preview" width="200" />
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                style={{ padding: '0.75rem' }}
                            >
                                {offerId ? "Update Offer" : "Create Offer"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default OwnersOfferCreator;
