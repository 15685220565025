import React, { useEffect } from 'react';
import { Typography, Box, Divider, Grid, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { format } from 'date-fns';

const OfferDetails = ({ offer }) => {
    const { latitude, longitude, phone1, phone2, store_name, address } = offer;

    const getDirectionsUrl = (lat, lng) => {
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    };
    const isNotNull = (val)=>{
        return Boolean(val) && val !== 'null'
    }  

    let offerTerms = []
    try{
        let temp = JSON.parse(offer.terms)
        if(Array.isArray(temp)){
            offerTerms = temp
        }
    }catch{

    }

    return (
        <Box sx={{ padding: 3 }}>
            {/* Banner Image */}
            {offer.banner_image && (
                <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <img
                        src={window.getMediaImageURL(offer.banner_image)}
                        alt={`${offer.offer_title} banner`}
                        style={{ width: '100%', borderRadius: '8px', maxHeight: '250px', objectFit: 'cover' }}
                    />
                </Box>
            )}

            {/* Offer Title */}
            <Typography variant="h4" gutterBottom>{offer.offer_title}</Typography>

            {/* Offer Description */}
            {isNotNull(offer.offer_description) && (
                <Typography variant="body1" color="text.secondary" paragraph>
                    {offer.offer_description}
                </Typography>
            )}

            <Divider sx={{ my: 2 }} />

            {/* Store Information */}
            {isNotNull(store_name) && (
                <Typography variant="h6" color="text.primary" gutterBottom>{store_name}</Typography>
            )}
            {isNotNull(address) && (
                <Typography variant="body2" color="text.secondary" paragraph>{address}</Typography>
            )}

            {/* Phone Numbers */}
            {(isNotNull(phone1) || isNotNull(phone2)) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    {phone1 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PhoneIcon />}
                            href={`tel:${phone1}`}
                            sx={{ mb: 1 }}
                        >
                            {phone1}
                        </Button>
                    )}
                    {phone2 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PhoneIcon />}
                            href={`tel:${phone2}`}
                        >
                            {phone2}
                        </Button>
                    )}
                </Box>
            )}

            {/* Get Directions Button */}
            {latitude && longitude && (
                <Box sx={{ mb: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<LocationOnIcon />}
                        href={getDirectionsUrl(latitude, longitude)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Get Directions
                    </Button>
                </Box>
            )}

            <Divider sx={{ my: 2 }} />

            {/* Offer Details */}
            <Grid container spacing={2}>
                {isNotNull(offer.discount) && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Discount: {offer.discount}{offer.discount_type}
                        </Typography>
                    </Grid>
                )}
                {isNotNull(offer.discount_code) && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Discount Code: {offer.discount_code}
                        </Typography>
                    </Grid>
                )}
                {isNotNull(offer.min_purchase) && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Minimum Purchase: ₹{offer.min_purchase}
                        </Typography>
                    </Grid>
                )}
                {isNotNull(offer.start_date) && isNotNull(offer.expiry_date) && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Valid From: {format(new Date(offer.start_date), 'yyyy-MM-dd')} to {format(new Date(offer.expiry_date), 'yyyy-MM-dd')}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Terms and Additional Details */}
            { offerTerms.length>0 && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Terms and Conditions</Typography>
                    <Typography component="ul" variant="body2" color="text.secondary">
                        {JSON.parse(offer.terms).map((term, index) => (
                            <li key={index}>{term}</li>
                        ))}
                    </Typography>
                </Box>
            )}
            {isNotNull(offer.additional_details) && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Additional Details</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {offer.additional_details}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default OfferDetails;
