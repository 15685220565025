import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, Grid, Button, IconButton, Switch } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { apiPostJson } from '../../../Utilities/API';

const OfferDetails = ({ offer, fetchOfferList , onClose}) => {
    const navigate = useNavigate()
    const { latitude, longitude, phone1, phone2, store_name, address } = offer;
    const [enableOffer, setEnableOffer] = useState(Boolean(offer?.offer_enable))
    const getDirectionsUrl = (lat, lng) => {
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    };

    const toggleOfferEnable = (offer_id)=>{
        apiPostJson(
            'ag-api/ag-admin/',
            { identifier: 'enable_offer', offer_id:offer_id, enable:!enableOffer},
            (response)=>{console.log(response)},
            (response)=>{console.log(response)}
        )
        setEnableOffer(!enableOffer)
        // if(fetchOfferList){
        //     fetchOfferList()
        // }
    }

    const onOfferDelete = (offer_id)=>{
        apiPostJson(
            'ag-api/ag-admin/',
            { identifier: 'delete_offer', offer_id:offer_id},
            (response)=>{console.log(response); onClose(); fetchOfferList()},
            (response)=>{console.log(response)}
        )
    }

    return (
        <Box sx={{ padding: 3,  }} >
            {/* Banner Image */}
            {offer.banner_image && (
                <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <img
                        src={window.getMediaImageURL(offer.banner_image)}
                        alt={`${offer.offer_title} banner`}
                        style={{ width: '100%', borderRadius: '8px', maxHeight: '250px', objectFit: 'cover' }}
                    />
                </Box>
            )}

            {/* Offer Title */}
            <Typography variant="h4" gutterBottom>{offer.offer_title}</Typography>

            {/* Offer Description */}
            {offer.offer_description && (
                <Typography variant="body1" color="text.secondary" paragraph>
                    {offer.offer_description}
                </Typography>
            )}

            <Divider sx={{ my: 2 }} />

            {/* Store Information */}
            {/* <Typography variant="h5" gutterBottom>Store Information</Typography> */}
            {store_name && (
                <Typography variant="h6" color="text.primary" gutterBottom>{store_name}</Typography>
            )}
            {address && (
                <Typography variant="body2" color="text.secondary" paragraph>{address}</Typography>
            )}

            {/* Phone Numbers */}
            {(phone1 || phone2) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    {phone1 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PhoneIcon />}
                            href={`tel:${phone1}`}
                            sx={{ mb: 1 }}
                        >
                            {phone1}
                        </Button>
                    )}
                    {phone2 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PhoneIcon />}
                            href={`tel:${phone2}`}
                        >
                            {phone2}
                        </Button>
                    )}
                </Box>
            )}

            {/* Get Directions Button */}
            {latitude && longitude && (
                <Box sx={{ mb: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<LocationOnIcon />}
                        href={getDirectionsUrl(latitude, longitude)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Get Directions
                    </Button>
                </Box>
            )}

            <Divider sx={{ my: 2 }} />

            {/* Offer Details */}
            <Grid container spacing={2}>
                {offer.discount && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Discount: {offer.discount}{offer.discount_type}
                        </Typography>
                    </Grid>
                )}
                {offer.discount_code && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Discount Code: {offer.discount_code}
                        </Typography>
                    </Grid>
                )}
                {offer.min_purchase && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Minimum Purchase: ₹{offer.min_purchase}
                        </Typography>
                    </Grid>
                )}
                {offer.start_date && offer.expiry_date && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            Valid From: {format(new Date(offer.start_date), 'yyyy-MM-dd')} to {format(new Date(offer.expiry_date), 'yyyy-MM-dd')}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Terms and Additional Details */}
            {offer.terms && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Terms and Conditions</Typography>
                    <Typography component="ul" variant="body2" color="text.secondary">
                        {JSON.parse(offer.terms).map((term, index) => (
                            <li key={index}>{term}</li>
                        ))}
                    </Typography>
                </Box>
            )}
            {offer.addtional_details && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Additional Details</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {offer.addtional_details}
                    </Typography>
                </Box>
            )}
            <Typography variant="h6" sx={{marginTop:'8px'}}>
                View Count: {offer.offer_view_count}
            </Typography>
            <Button 
                sx={{marginTop:'8px'}}   
                onClick={() => navigate(`/ag-admin/create-offer/?offerID=${offer.offer_id}`)}
                startIcon={<Edit />}
            >Edit Offer</Button>
            <Typography variant='h6' className='my-5'>
                Enable: <Switch
                checked={enableOffer}
                onChange={() => toggleOfferEnable(offer.offer_id)}
                color="primary"
            />
            <Button variant='outlined' endIcon={<Delete/>} onClick={()=>onOfferDelete(offer.offer_id)} className='ms-5' color='warning'>Delete</Button>
            </Typography>
        </Box>
    );
};

export default OfferDetails;
